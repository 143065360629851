import React from "react"
import styled from "styled-components"
import LocalizedLink from "../../Atoms/LocalizedLink"
import Grid from "../../Atoms/Grid"
import RealizationElement from "../../Atoms/RealizationElement"
import realization1 from "../../../assets/images/realizations/bialystok1/bialystok-mini.jpg"
import realization2 from "../../../assets/images/realizations/bialystok2/bialystok2-mini.jpg"
import realization3 from "../../../assets/images/realizations/mariampol/mariampol-mini.jpg"
import { localize } from "../../Atoms/Language"

const StyledButton = styled(LocalizedLink)`
  @media screen and (max-width: 900px) {
    text-align: center;
    width: 100%;
    text-decoration: underline;
    font-size: 3rem;
  }
`

const StyledGrid = styled(Grid)`
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
`

const SampleProjects = ({ language }) => (
  <section className="mt16 mb16">
    <div className="container">
      <h2 className="header2">{localize("182")}</h2>
      <StyledGrid>
        <RealizationElement language={language} url="/realizacje/bialystok-1" image={realization1} name={localize("183")} type={localize("186")} />
        <RealizationElement language={language} url="/realizacje/bialystok-2" image={realization2} name={localize("184")} type={localize("187")} />
        <RealizationElement language={language} url="/realizacje/mariampol" image={realization3} name={localize("185")} type={localize("187")} />
      </StyledGrid>
      <StyledButton to="/o-nas#realizacje" isButton language={language} className="mt8">
        {localize("188")}
      </StyledButton>
    </div>
  </section>
)

export default SampleProjects
