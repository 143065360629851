import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../assets/styles/GlobalVariables"
import LocalizedLink from "../../components/Atoms/LocalizedLink"
import { localize } from "./Language"

const StyledWrapper = styled(LocalizedLink)`
  width: calc(33.3333% - 20px);
  margin-bottom: 64px;
  text-decoration: none;
  margin-right: 30px;

  @media screen and (min-width: 1024px) {
    :nth-child(3n) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    width: calc(50% - 20px);
    
    :nth-child(2n) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  :hover .image::after {
    transform: scale(1.1);
  }
`

const StyledImage = styled.div`
    width: 100%;
    height: 280px;
    border: 1px solid #D3DBE6;
    border-radius: 4px;
    overflow: hidden;
    
    :after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-image: url('${({ image }) => image}');
      background-repeat: no-repeat;
      background-position: center;
    transition: transform .3s ease-in-out;
    }
`

const StyledName = styled.span`
  display: block;
  font-family: Teko;
  color: ${GlobalColors.primary};
  margin-top: 20px;
  text-transform: uppercase;
`

const OfferElement = ({ offer, language }) => {
  const { name, image, link } = offer
  return (
    <StyledWrapper language={language} to={link}>
      <StyledImage image={image} className="image"/>
      <StyledName className="header5">{localize(name)}</StyledName>
    </StyledWrapper>
  )
}

export default OfferElement
