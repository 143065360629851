import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import { localize } from "../../Atoms/Language"

const StyledWrapper = styled.section`
  p {
    color: ${GlobalColors.gray600};
  }

  @media screen and (max-width: 900px) {
    &.container {
      margin-top: 60px;
    }
  }
`

const ModernCarWashes = () => (
  <StyledWrapper className="container">
    <h4 className="header4">{localize("179")}</h4>
    <p className="body">{localize("180")}</p>
    <p className="body">{localize("181")}</p>
  </StyledWrapper>
)

export default ModernCarWashes
