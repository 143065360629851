import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import phoneIcon from "../../../assets/images/icons/Phone-1.svg"
import paymentIcon from "../../../assets/images/icons/Payment.svg"
import settingsIcon from "../../../assets/images/icons/Settings.svg"
import loyaltyIcon from "../../../assets/images/icons/Loyalty.svg"
import lightsIcon from "../../../assets/images/icons/Lights.svg"
import desktopIcon from "../../../assets/images/icons/Desktop.svg"
import { localize } from "../../Atoms/Language"

const StyledWrapper = styled.section`
  background-color: ${GlobalColors.black};
  padding: 120px 0;
  
  @media screen and (max-width: 600px) {
    padding: 60px 0;
  }
`

const StyledHeader = styled.h2`
  color: ${GlobalColors.white};
  margin: 0;
`
const StyledUnderHeader = styled.h3`
  color: ${GlobalColors.gray500};
  margin: 0;
`
const StyledContent = styled.ul`
  padding: 0;
  color: ${GlobalColors.white};
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  @media screen and (max-width: 900px) {
    flex-direction: column;
    
    li {
      width: 100%;
    }
  }
`
const StyledElement = styled.li`
  padding-left: 90px;
  padding-right: 30px;
  color: ${GlobalColors.white};
  position: relative;
  width: 33.33333%;
  margin-bottom: 40px;
  
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  
  @media screen and (max-width: 640px) {
    width: 100%;
  }
  
  p {
    color: ${GlobalColors.gray500};
  }
  
  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 64px;
    height: 64px;
    background-color: ${GlobalColors.primary};
    background-image: url(${({ icon }) => icon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const WhatMakesUsStandOut = () => (
  <StyledWrapper>
    <div className="container">
      <StyledHeader className="header2">{localize("138")}</StyledHeader>
      <StyledUnderHeader className="header4">{localize("139")}</StyledUnderHeader>
      <StyledContent className="mt8">
        <StyledElement icon={phoneIcon}>
          <span className="label">{localize("140")}</span>
          <p className="body">{localize("141")}</p>
        </StyledElement>
        <StyledElement icon={settingsIcon}>
          <span className="label">{localize("142")}</span>
          <p className="body">{localize("143")}</p>
        </StyledElement>
        <StyledElement icon={lightsIcon}>
          <span className="label">{localize("144")}</span>
          <p className="body">{localize("145")}</p>
        </StyledElement>
        <StyledElement icon={paymentIcon}>
          <span className="label">{localize("146")}</span>
          <p className="body">{localize("147")}</p>
        </StyledElement>
        <StyledElement icon={loyaltyIcon}>
          <span className="label">{localize("148")}</span>
          <p className="body">{localize("149")}</p>
        </StyledElement>
        <StyledElement icon={desktopIcon}>
          <span className="label">{localize("150")}</span>
          <p className="body">{localize("151")}</p>
        </StyledElement>
      </StyledContent>
    </div>
  </StyledWrapper>
)

export default WhatMakesUsStandOut
