import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import LocalizedLink from "../../Atoms/LocalizedLink"
import { localize } from "../../Atoms/Language"

const StyledHeader = styled.h2`
  max-width: 670px;
  margin: 0;
`

const StyledUnderHeader = styled.h4`
  color: ${GlobalColors.gray500};
  max-width: 475px;
`

const StyledContent = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    flex-direction: column;

    li {
      width: 100%;
    }
  }
`

const StyledElement = styled.li`
  width: 33.333333%;
  padding-left: 90px;
  padding-right: 30px;
  position: relative;
  margin-bottom: 40px;
  height: 64px;
  
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  
  @media screen and (max-width: 640px) {
    width: 100%;
  }

  
  ::before {
    content: '${({ step }) => step}';
    font-family: Teko;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 3.2rem;
    position: absolute;
    left: 0;
    top: -15px;
    width: 64px;
    height: 64px;
    background-color: ${GlobalColors.primary};
    border-radius: 50%;
    color: ${GlobalColors.white};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const HowToBecomeOwner = ({ language }) => (
  <div className="container mt16 mb16">
    <StyledHeader className="header2">
      {localize("152")}
    </StyledHeader>
    <StyledUnderHeader className="header4">
      {localize("153")}
    </StyledUnderHeader>
    <StyledContent className="mt8">
      <StyledElement className="label" step="1">
        {localize("154")}
      </StyledElement>
      <StyledElement className="label" step="2">
        {localize("155")}
      </StyledElement>
      <StyledElement className="label" step="3">
        {localize("156")}
      </StyledElement>
      <StyledElement className="label" step="4">
        {localize("157")}
      </StyledElement>
      <StyledElement className="label" step="5">
        {localize("158")}
      </StyledElement>
      <StyledElement className="label" step="6">
        {localize("159")}
      </StyledElement>
    </StyledContent>
    <LocalizedLink isStyledLink language={language} to="/dla-inwestora#etapy-inwestycji">
      {localize("160")}
    </LocalizedLink>
  </div>
)

export default HowToBecomeOwner
