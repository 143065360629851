import React from "react"
import styled from "styled-components"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import LocalizedLink from "../../Atoms/LocalizedLink"
import { localize } from "../../Atoms/Language"


const imageQuery = graphql`
  {
    file(name: { eq: "carwash2" }) {
      childImageSharp {
        fixed(width: 540, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledButton = styled(LocalizedLink)`
  margin-top: 40px;
`

const Cooperation = ({language}) => {
  const data = useStaticQuery(imageQuery)

  return (
      <ImageWithText className="mt16 mb16" fixed={data.file.childImageSharp.fixed} imageAltText={localize("345")} isRevert>
        <h2 className="header2">{localize("134")}</h2>
        <p className="body">{localize("135")}</p>
        <p className="body">{localize("136")}</p>
        <StyledButton to="/kontakt" isButton language={language}>{localize("137")}</StyledButton>
      </ImageWithText>
  )
}

export default Cooperation
