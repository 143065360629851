import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "carwash1" }) {
      childImageSharp {
        fixed(width: 540, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledHeader = styled.h2`
  margin-top: 45px;
`

const CarWashProducer = () => {
  const data = useStaticQuery(imageQuery)

  return (
    <ImageWithText className="mt16 mb16" fixed={data.file.childImageSharp.fixed} imageAltText={localize("344")}>
        <StyledHeader className="header2">{localize("126")}</StyledHeader>
        <p className="body">{localize("127")}</p>
    </ImageWithText>
  )
}

export default CarWashProducer
