import React, { Component } from "react"
import styled from "styled-components"
import ContactForm from "../../Molecules/ContactForm/ContactForm"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import { localize } from "../../Atoms/Language"

const StyledWrapper = styled.div`
  border-top: 1px solid #D3DBE6;
  
  p {
    color: ${GlobalColors.gray600};
  }
`

class HomeContactForm extends Component {
  render() {
    return (
      <StyledWrapper className="pt16 pb16">
        <div className="container">
          <ContactForm>
            <h2 className="header2">{localize("189")}</h2>
            <p className="body-large">{localize("190")}</p>
            <p className="body-large">{localize("191")}</p>
          </ContactForm>
        </div>
      </StyledWrapper>
    )
  }
}

export default HomeContactForm
