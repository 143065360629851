import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../assets/styles/GlobalVariables"
import washIcon from "../../assets/images/icons/Wash.svg"
import LocalizedLink from "./LocalizedLink"
import { localize } from "./Language"

const StyledWrapper = styled.li`
  flex: 1;
  min-width: 30%;
  margin-right: 30px;
  overflow: hidden;

  :nth-child(3n) {
    margin-right: 0;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 500px;
    margin: 0 0 40px;
    
    img {
      width: 100% !important;
    }
  }
`

const StyledName = styled.h4`
  display: block;
  margin-bottom: 0;
  color: ${GlobalColors.black};
  text-transform: uppercase;
`

const StyledLink = styled(LocalizedLink)`
  text-decoration: none;
  
  img {
      max-width: 100%;
  }
`

const StyledCity = styled.span`
  display: flex;
  color: ${GlobalColors.gray500};
  align-items: center;
  
  ::before {
    content: url(${washIcon});
    margin-right: 10px;
  }
`

const RealizationElement = ({ name, image, type, url, language, className }) => (
  <StyledWrapper className={className}>
    <StyledLink to={url} language={language}>
      <img src={image} alt={localize("343") + name}/>
      <StyledName className="header4">{name}</StyledName>
      <StyledCity className="body">{type}</StyledCity>
    </StyledLink>
  </StyledWrapper>
)

export default RealizationElement
