import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import image from "../../../assets/images/home-producer.jpg"
import { localize } from "../../Atoms/Language"

const StyledWrapper = styled.section`
  padding-bottom: 80px;
  p {
    color: ${GlobalColors.gray600};
  }
`

const StyledImage = styled.img`
  margin: 40px 0;
  width: 100%;
`

const HomeProducer = () => (
  <StyledWrapper className="container">
    <h4 className="header4">{localize("173")}</h4>
    <p className="body">{localize("174")}</p>
    <p className="body">{localize("175")}</p>
    <StyledImage src={image} alt={localize("346")} />
    <p className="body">{localize("176")}</p>
    <p className="body">{localize("177")}</p>
    <p className="body">{localize("178")}</p>
  </StyledWrapper>
)

export default HomeProducer
