import React from "react"
import styled from "styled-components"
import unionIcon from "../../../assets/images/icons/Union.svg"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import { localize } from "../../Atoms/Language"

const StyledContent = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin-top: 40px;

  li {
    color: ${GlobalColors.gray600};
    min-width: 290px;
    width: 33.3333%;
    margin-bottom: 40px;
    position: relative;
    padding: 0 40px;
    height: 70px;
    ::before {
      content: url(${unionIcon});
      display: block;
      width: 22px;
      height: 22px;
      position: absolute;
      left: 0;
      top: 5px;
    }

    @media screen and (max-width: 970px) {
      height: auto;
      width: 100%;
    }
  }
`

const BenefitsWorkWithAs = () => (
  <div className="container">
    <h2 className="header2">{localize("128")}</h2>
    <StyledContent>
      <li className="body">{localize("129")}</li>
      <li className="body">{localize("130")}</li>
      <li className="body">{localize("131")}</li>
      <li className="body">{localize("132")}</li>
      <li className="body">{localize("133")}</li>
    </StyledContent>
  </div>
)

export default BenefitsWorkWithAs
