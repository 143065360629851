import React from "react"
import Hero from "../components/Organisms/Hero/Hero"
import CarWashProducer from "../components/Organisms/StaticSections/CarWashProducer"
import BenefitsWorkWithAs from "../components/Organisms/StaticSections/BenefitsWorkWithAs"
import Cooperation from "../components/Organisms/StaticSections/Cooperation"
import WhatMakesUsStandOut from "../components/Organisms/StaticSections/WhatMakesUsStandOut"
import HowToBecomeOwner from "../components/Organisms/StaticSections/HowToBecomeOwner"
import SmallCalculator from "../components/Organisms/Calculator/SmallCalculator"
import Offer from "../components/Organisms/Offer/Offer"
import HomeProducer from "../components/Organisms/StaticSections/HomeProducer"
import ModernCarWashes from "../components/Organisms/StaticSections/ModernCarWashes"
import SampleProjects from "../components/Organisms/SampleProjects/SampleProjects"
import HomeContactForm from "../components/Organisms/HomeContactForm/HomeContactForm"
import { WithIntl } from "../components/Atoms/withTranslate"
import SEO from "../components/seo"
import { localize } from "../components/Atoms/Language"

const IndexPage = ({ language }) => (
  <>
    <SEO
      title={localize("121")}
      description={localize("122")}
      lang={language}
    />
    <Hero language={language}/>
    <CarWashProducer language={language}/>
    <BenefitsWorkWithAs language={language}/>
    <Cooperation language={language}/>
    <WhatMakesUsStandOut language={language}/>
    <HowToBecomeOwner language={language}/>
    <SmallCalculator language={language}/>
    <Offer withHeader={true} language={language}/>
    <HomeProducer language={language}/>
    <ModernCarWashes language={language}/>
    <SampleProjects language={language}/>
    <HomeContactForm language={language}/>
  </>
)

export default WithIntl()(IndexPage)
