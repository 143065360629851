import styled from "styled-components"

const Grid = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style: none;
  flex-wrap: wrap;
  
  @media screen and (max-width: 600px) {
    
  }
`
export default Grid
