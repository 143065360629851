import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import heroImage from "../../../assets/images/Header.jpg"
import LocalizedLink from "../../Atoms/LocalizedLink"
import { localize } from "../../Atoms/Language"

const StyledHeroWrapper = styled.section`
  width: 100%;
  height: 540px;
  background-color: ${GlobalColors.black};

  .content {
    background-image: url(${heroImage});
    background-position: right bottom;
    background-repeat: no-repeat;
    max-width: 1300px;
    margin: 0 auto;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: 850px) {
      align-items: flex-end;
      padding-bottom: 20px;
    }
  }
`
const StyledContent = styled.div`
  width: 60%;
  animation: slide-from-left 1s ease-in-out;
  h1 {
    font-size: 7.2rem;
    text-transform: uppercase;
    margin: 0;
  }
  @media screen and (max-width: 650px) {
    width: 100%;

    h1 {
      font-size: 6.2rem;
    }
  }

  span {
    font-family: Inter;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 2.4rem;
    display: block;
  }
`
const StyledButton = styled(LocalizedLink)`
  margin-top: 75px;
`

const Hero = ({ language }) => (
  <StyledHeroWrapper>
    <div className="content">
      <div className="container">
        <StyledContent>
          <h1>{localize("123")}</h1>
          <span>{localize("124")}</span>
          <StyledButton to="/myjnie-bezdotykowe" isButton language={language}>
            {localize("125")}
          </StyledButton>
        </StyledContent>
      </div>
    </div>
  </StyledHeroWrapper>
)

export default Hero
