import React from "react"
import image1 from "../../../assets/images/offer/image 1.png"
import image2 from "../../../assets/images/offer/image 2.png"
import image3 from "../../../assets/images/offer/image 3.png"
import image4 from "../../../assets/images/offer/image 4.png"
import image5 from "../../../assets/images/offer/image 5.png"
import image6 from "../../../assets/images/offer/image 6.png"
import OfferElement from "../../Atoms/OfferElement"
import Grid from "../../Atoms/Grid"
import { localize } from "../../Atoms/Language"

const offerData = [
  {
    name: 3,
    image: image1,
    link: "/myjnie-kontenerowe",
  },
  {
    name: 4,
    image: image2,
    link: "/myjnie-modulowe",
  },
  {
    name: 5,
    image: image3,
    link: "/rodzaje-wiat",
  },
  {
    name: 6,
    image: image4,
    link: "/chemia",
  },
  {
    name: 7,
    image: image5,
    link: "/akcesoria",
  },
  {
    name: 8,
    image: image6,
    link: "/czesci-do-myjni",
  },
]

const Offer = ({ withHeader, language }) => (
    <div className="container mt16 mb16">
      {withHeader && <h2 className="header2">{localize("172")}</h2>}
      <Grid>
        {offerData.map((offer, index) => (
          <OfferElement key={index} offer={offer} language={language}/>
        ))}
      </Grid>
    </div>
)

export default Offer
